
<template>
    <div>
       <div class="Band">
            <img src="../draw/img/head.png" alt=""  class="Mook" >
       </div>
       <div class="Sand">
            <div class="Poss">
                <input type="text" class="Rand" placeholder="请输入手机号" v-model="phone">
                <div class="Moos">
                    <input type="text" class="Cand" style="margin-top: 0px ;" placeholder="请输入验证码" v-model="number">
                    <div class="Pands">
                        <p v-if="Kand" @click="huoqu()">获取验证码</p>
                        <p v-if="!Kand">{{ num }}</p>
                    </div>
                </div>
                <div class="Good" @click="denglu()">
                   <img src="../draw/img/charge.png" alt="" class="Bodin">
                </div>
            </div>
       </div>
       <div class="Urand">
            <p v-if="Usen">2023年05月25日24:00 之前有效</p>
            <p v-if="!Usen">活动已结束</p>
       </div>
    </div>
</template>


<script>
import {sendSms,codelogin,storepig } from "../../api/index"
    export default{
        data(){
            return{
                num:60,
                Kand:true,
                phone:"",
                number:"",
                ticket:"",
                randstr:"",
                Usernumber:'',
                Usen:true
            }
        },
        created(){
            var data = new Date()
            var time1 = data.getTime()
            var date = new Date("2023-5-26")
            var time2 = date.getTime()
            if(date>data){
                this.Usen = true
            }else{
                this.Usen = false
            }
           console.log(time1,date,time2)
        },
        methods:{
            huoqu(){
            this.ticket = Math.floor(Math.random()*(9999-1000))+1000
            this.randstr = Math.floor(Math.random()*(9999-1000))+1000
            this.sendSmsPath()
        },
        daojishi(){
        var that = this
              var sass =  setInterval(()=>{
                that.num--
                    if(that.num == 0){
                        that.Kand = true
                        that.num = 60
                        clearInterval(sass)
                    }
                },1000)
        },
        denglu(){
            this.codelogin()
            this.getCookie("Usernumber")
        },
        async storepig(){
            let data = {
                access_id:localStorage.getItem('access_id')
            }
            let res = await storepig(data)
            if(res.data.code == 200){
                this.$toast("领取成功")
                this.setCookie("Usernumber",1,2880)
                setTimeout(()=>{
                    this.$router.push({
                        path:"/Skippage",
                        query:{scene:'Home',pageName:"index"}
                    }) 
                },1000)
            }else{
                    this.$toast(res.data.message)
                    setTimeout(()=>{
                        this.$router.push({
                            path:"/Skippage",
                            query:{scene:'Home',pageName:"index"}
                        })
                    },2000)
            }
        },
        async codelogin(){
            let data = {
                rid:1107,
                phone:this.phone,
                keyCode:this.number,
            }
            let res = await codelogin(data)
            if(res.data.code==200){
                localStorage.setItem('access_id',res.data.data.access_id)
                this.storepig()
            }else{
                this.$toast(res.data.message)
            }       
            // console.log(res)
        },
        //存
        setCookie(name,val,min){
                var now=new Date();
                //设置时间
                now.setMinutes(now.getMinutes()+min)
                //设置Cookie
                document.cookie=name+'='+val+';expires='+now.toUTCString()
        },

        //取
        getCookie(cookieName) {
        const strCookie = document.cookie
        const cookieList = strCookie.split(';')
        
        for(let i = 0; i < cookieList.length; i++) {
            const arr = cookieList[i].split('=')
            if (cookieName === arr[0].trim()) {
              this.Usernumber = arr[1]
            }
        }
},
          async  sendSmsPath(){
                let data = {
                    phone:this.phone,
                    type1: 1,
                    ticket:this.ticket,
                    randstr:this.randstr
                }
                let res = await  sendSms(data)
                if(res.data.code == 200){
                    this.daojishi()
                    this.Kand = false
                    this.$toast(res.data.message)
                }else{
                    this.$toast(res.data.message)
                }
            }
        }
    }
</script>


<style lang="scss"  >
    body{
       background: #0AAB65;
    }
   .Mook{
        width: 100%;
        height: 50vh;
   }
   .Sand{
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
   }
   .Poss{
        width: 90vw;
        border-radius: 30px;
        background: #ffffff;
   }
   .Rand{
        width: 85%;
        height:80px;
        background:#F2F2F2 ;
        border: 0;
        margin: 30px 30px 30px 30px;
        padding: 0 0 0 40px;
        border-radius: 30px;
        font-size: 30px;
   }
   .Cand{
        width: 45%;
        height:80px;
        background:#F2F2F2 ;
        border: 0;
        margin: 30px 0 30px 30px;
        padding: 0 0 0 40px;
        border-radius: 30px 0 0 30px ;
        font-size: 30px;
        border-right: 2px solid #e0e0e0;
   }
   .Pands{
        width: 40%;
        height:80px;
        line-height: 80px;
        background:#F2F2F2 ;
        text-align: center;
        border-radius: 0 30px 30px 0 ;
        font-size: 30px;
        color:#0AAB65 ;
   }
   .Good{
      display: flex;
      justify-content: center;
   }

   .Bodin{
        width: 90%;
        height: 120px;
   }
   .Urand{
    text-align: center;
    margin-top: 100px;
    font-size: 28px;
    color: #DDFF58;
   }
   .Moos{
    display: flex;
   }
</style>